import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import { HTMLContent } from '../../components/Content';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const PrivacyPageTemplate = ({ content, helmet }) => (
  <div className='page-container container content'>
    {helmet || ''}
    <HTMLContent content={content} />
  </div>
);

const PrivacyPage = ({ data, location }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <PrivacyPageTemplate
        location={location}
        content={frontmatter.content}
        helmet={
          <MetaComponent
            title={`${frontmatter.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${frontmatter.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
          />
        }
      />
    </Layout>
  );
};

export default PrivacyPage;

export const pageQuery = graphql`
  query privacyPageQueryZHHANS {
    markdownRemark(
      frontmatter: { templateKey: { eq: "privacy-page-zhhans" } }
    ) {
      frontmatter {
        title
        seoObject {
          metaTitle
          metaDescription
        }
        content
      }
    }
  }
`;
